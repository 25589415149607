import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from "../material.module";

import { CoreRoutingModule } from "./core-routing.module";
import { AppHeaderComponent } from "./components/app-header.component";
import { DashboardComponent } from "./components/dashboard.component";
import { LoginComponent } from './components/login.component';
import { PageNotFoundComponent } from './components/page-not-found.component';

@NgModule({
  declarations: [
    AppHeaderComponent,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule, 
    CoreRoutingModule, 
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AppHeaderComponent,
    DashboardComponent
  ]
})
export class CoreModule {}
