import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from './api/services/auth.service';
import { AuthStatus } from './api/model/authStatus';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'meals-app';

  /**
   * @ignore
   */
  destroy = new Subject();

  constructor(private authService : AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.authStatusBehaviorSubject.pipe(takeUntil(this.destroy)).subscribe((authStatus: AuthStatus) => {
      if(authStatus && authStatus.userIsAuthenticated) {
        this.router.navigate(['meals']);
      } else {
        this.router.navigate(['login']);
      }
    });
  }

  ngOnDestroy() {
    // Clean up the subscriptions
    this.destroy.next();
    this.destroy.complete();
  }
}
