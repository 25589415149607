import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AngularFireAuthGuard, redirectLoggedInTo } from '@angular/fire/auth-guard';

import { PageNotFoundComponent } from "../app/core/components/page-not-found.component";
import { LoginComponent } from '../app/core/components/login.component';

const redirectLoggedInToMeals = () => redirectLoggedInTo(['meals']);

const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToMeals } },
  { path: "meals", loadChildren: () => import('./meals/meals.module').then(m => m.MealsModule) },
  { path: "pantry", loadChildren: () => import('./pantry/pantry.module').then(m => m.PantryModule) },
  { path: "", redirectTo: "/meals", pathMatch: "full" },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
