import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../../api/services/auth.service';
import { AuthStatus } from '../../api/model/authStatus';

@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"]
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  /**
   * @ignore
   */
  destroy = new Subject();

  private userIsAuthenticated: boolean;

  constructor(private authService : AuthService) {
    this.userIsAuthenticated = false;
  }

  ngOnInit() {
    this.authService.authStatusBehaviorSubject.pipe(takeUntil(this.destroy)).subscribe((authStatus: AuthStatus) => {
      this.userIsAuthenticated = authStatus.userIsAuthenticated;
    });
  }

  ngOnDestroy() {
    // Clean up the subscriptions
    this.destroy.next();
    this.destroy.complete();
  }

  signOut() {
    this.authService.signOut();
  }
}
