// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyByiIz5wKLxrzeaucyWklJEtnSfhgTB0TI",
    authDomain: "llewnroc-pantry.firebaseapp.com",
    databaseURL: "https://llewnroc-pantry.firebaseio.com",
    projectId: "llewnroc-pantry",
    storageBucket: "llewnroc-pantry.appspot.com",
    messagingSenderId: "806827967505",
    appId: "1:806827967505:web:219552f98a017ce4e61db0",
    measurementId: "G-7L5FEL60FC"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
