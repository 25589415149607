import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { BehaviorSubject, Observable } from 'rxjs';

import { AuthStatus } from '../model/authStatus';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authStatus: AuthStatus;
  public authStatusBehaviorSubject;

  constructor(private afAuth: AngularFireAuth) {
    this.authStatus = {
      userIsAuthenticated: false
    };

    this.authStatusBehaviorSubject = new BehaviorSubject(this.authStatus);

    afAuth.authState.subscribe(user => {
      console.log('afAuth - authState received - ', user);
      this.authStatus.userIsAuthenticated = !!user;
      this.pushAuthStatus();
    });
  }

  private pushAuthStatus() : void {
    this.authStatusBehaviorSubject.next(this.authStatus);
  }

  signIn(username: string, password: string): Promise<any> {
    const self = this;
    const userCredPromise = new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(username, password).then(
        (userCredentialResp) => {
          this.authStatus.userIsAuthenticated = true;
          //this.pushAuthStatus();
          resolve(userCredentialResp);
        },
        (errResp) => {
          this.authStatus.userIsAuthenticated = false;
          this.pushAuthStatus();
          reject(errResp);
        }
      );
    });

    return userCredPromise;
  }

  signOut(): Promise<void> {
    this.authStatus.userIsAuthenticated = false;
    //this.pushAuthStatus();
    return this.afAuth.auth.signOut();
  }
}
